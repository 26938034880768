<template>
    <div class="rankList">
        <div v-if="rankList.my" class="rankTitle flex alignCenter" style="margin-top:24px;margin-left:20px;">
            <span style="padding-left:54px;">当前排名 {{rankList.my.rank}}</span>
            <span style="padding-left:102px;">累计积分 {{rankList.my.point}}</span>
            <span style="font-size:12px;padding-left:102px;">距上一名还差  {{rankList.my.diff_point}}  个积分</span>
        </div>
        <table cellspacing="0" style="margin-left:20px;">
            <tr style="height:66px;">
                <th>排行</th>
                <th>头像</th>
                <th>{{$route.query.showType != 2 ? '姓名' : '机构名称'}}</th>
                <th>勋章</th>
                <th v-show="$route.query.showType != 2">所属单位</th>
                <th>累计积分</th>
            </tr>
            <tr v-for="(im,ix) in rankList.rank_info" :key="ix">
                <td style="width:150px;">
                    <img v-show="im.ranking + 1 == 1" src="@/assets/img/personal/first.png" style="width:30px;height:43px;" alt="">
                    <img v-show="im.ranking + 1 == 2" src="@/assets/img/personal/sec.png" style="width:30px;height:43px;" alt="">
                    <img v-show="im.ranking + 1 == 3" src="@/assets/img/personal/thir.png" style="width:30px;height:43px;" alt="">
                    <span v-show="im.ranking + 1 > 3" class="flex alignCenter spacearound marginAuto" style="width:20px;height:20px;border-radius:50px;border:1px solid #333333;color:#333333;font-size:12px;">
                        {{im.ranking + 1}}
                    </span>
                </td>
                <td>
                    <img :src="im.portrait" style="width:60px;height:60px;border-radius:100px;" alt="">
                </td>
                <td>{{$route.query.showType != 2 ? im.real_name : im.organ_name ? im.organ_name : im.real_name ? im.real_name : '新医云机构'}}</td>
                <td>{{im.medal_name}}</td>
                <td v-show="$route.query.showType != 2">{{im.unit ? im.unit : '暂无单位'}}</td>
                <td>{{im.point}}</td>
            </tr>
        </table>
       <!-- <el-pagination
		v-if="pagIn"
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="rankList.count > 20 ? 20 : rankList.count "
        :page-size="5"
        @current-change="currentChange">
        </el-pagination> -->
    </div>
</template>
<script>
import { rank_list } from '@/utils/Api/userList'
export default {
    name:'rankList',
    data(){
        return{
            rankList:[],
            page: 1,
			pagIn: false
        }
    },
    created(){
        this.initialBefore(this.$route.query.showType)
    },
	watch:{
		$route(){
			this.page = 1
			this.pagIn = false
			this.initialBefore(this.$route.query.showType)
		}
	},
    computed:{
        user_capacity(){
            return this.$store.getters.personal.capacity
        }
    },
    methods:{
        initialBefore(value){
			// value  1 用户  2 机构
            let params = {
                type: this.$route.query.showType,
                page: this.page,
                limit: 20
            }
            this.initial(params)
        },
        async initial(params){
            let rankList = await rank_list(params)
            this.rankList = rankList
            let start = 5 * (this.page - 1)
            rankList.rank_info.forEach( (im, ix) => {
                im.ranking = start+ix
            });
			this.pagIn = true
            // this.tableData[this.page-1] = rankList.rank_info
        },
        currentChange(value){
            this.page = value
            this.initialBefore()
            this.scrollTop()
        }
    }
}
</script>
<style lang="scss" scoped>
.rankList{
    table{
        width: 913px;
        border: 1px solid #dfdfdf;
        tr{
            width: 100%;
            height: 100px;
            td{
                border-bottom: 1px solid #dfdfdf;
            }
        }
        th{
            font-size: 16px;
            color: #333;
            border-bottom: 1px solid #dfdfdf;
        }
    }
    .rankTitle{
        width: 913px;
        height: 66px;
        background: url('../../../../assets/img/personal/phb.png');
        background-size: cover;
        font-size: 16px;
        color: #fff;
    }
}
</style>